import timg from '../images/resource/Randeer-Sood.jpg'
import timg2 from '../images/resource/Gaurangadas.jpg'
import timg2a from '../images/resource/manjulangani.webp'
import timg3 from '../images/resource/Eng.-Ahmed-Al-Hosani-old.jpg'
import timg4 from '../images/resource/moran-cerf.webp'
import timg6 from '../images/resource/Subramanyam-Yadavalli.webp'
import timg13 from '../images/resource/dabboo-ratnani.jpg'
import timg14 from '../images/resource/anil-chanchlani.jpg'
import timg15 from '../images/resource/rajan-bandelkar.jpg'
import timg16 from '../images/resource/micheal-conner.webp'
import timg17 from '../images/resource/Linda-Salvin-min.jpg'
import timg18 from '../images/resource/nate-zeleznick-min.jpg'
import timg19 from '../images/resource/Ankit-Bhagheria.jpg'
import timg20 from '../images/resource/raajeev-kumaar.jpg'
import timg28 from '../images/resource/rajesh-kumar.jpg'

import tsimg from '../images/resource/Randeer-Sood.jpg'
import tsimg2 from '../images/resource/Gaurangadas.jpg'
import tsimg2a from '../images/resource/manjulangani.webp'
import tsimg3 from '../images/resource/Eng.-Ahmed-Al-Hosani-old.jpg'
import tsimg4 from '../images/resource/moran-cerf.webp'
import tsimg6 from '../images/resource/Subramanyam-Yadavalli.webp'
import tsimg13 from '../images/resource/dabboo-ratnani.jpg'
import tsimg14 from '../images/resource/anil-chanchlani.jpg'
import tsimg15 from '../images/resource/rajan-bandelkar.jpg'
import tsimg16 from '../images/resource/micheal-conner.webp'
import tsimg17 from '../images/resource/Linda-Salvin-min.jpg'
import tsimg18 from '../images/resource/nate-zeleznick-min.jpg'
import tsimg19 from '../images/resource/Ankit-Bhagheria.jpg'
import tsimg20 from '../images/resource/raajeev-kumaar.jpg'
import tsimg28 from '../images/resource/rajesh-kumar.jpg'



const Teams = [
   {
      Id: '1',
      tImg: timg17,
      tsImg: tsimg17,
      name: 'Dr. Linda Salvin, MPH, PhD',
      slug: 'https://linkedin.com/in/linda-salvin-mph-phd-85009411/',
      title: 'Veteran Radio Talk Show Host, Healer and Metaphysician, Creator of Wicks of Wisdom, USA',
   },
   {
      Id: '2',
      tImg: timg15,
      tsImg: tsimg15,
      name: 'Rajan Bandelkar',
      slug: 'https://www.linkedin.com/in/rajan-bandelkar-47710b128/',
      title: 'President, NAREDCO | Founder & MD, Raunak Group, India',
   },
   {
      Id: '3',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Randhir Sud',
      slug: 'https://www.linkedin.com/in/dr-randhir-sud-884659a3/',
      title: "Chairman, Institute of Digestive and Hepatobiliary Sciences, Medanta the Medicity, NCR, India",
   },
   {
      Id: '4',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Gauranga Das',
      slug: 'https://in.linkedin.com/in/gaurangadasofficial',
      title: 'Spiritual Leader, Sustainability Advocate, and Social Innovator, India',
   },
   {
      Id: '5',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Eng. Ahmed Al Hosani',
      slug: "https://www.linkedin.com/in/singer-ahmed-alhosani-8a329a231/",
      title: "UAE's First Classical Singer, Emirati Musicians' Association, UAE",
   },
   {
      Id: '6',
      tImg: timg14,
      tsImg: tsimg14,
      name: 'Anil Chanchlani',
      slug: 'javascript:void(0)',
      title: 'Promoter, AC Group of Companies, India',
   },
   {
      Id: '7',
      tImg: timg2a,
      tsImg: tsimg2a,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'https://www.linkedin.com/in/dr-manjula-anagani-a5283718/   ',
      title: 'Clinical Director, Women and Child Institute, Care Hospital, India',
   },
   {
      Id: '8',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Subramanyam Yadavalli',
      slug: 'https://linkedin.com/in/subramanyam-yadavalli-6ba30059',
      title: 'CEO, Apollo Hospitals, AP & International, India',
   },
   {
      Id: '9',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Moran Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '10',
      tImg: timg20,
      tsImg: tsimg20,
      name: 'Raajeev Kumaar',
      slug: 'linkedin.com/in/raajeev-kumaar-96a588102',
      title: 'Managing Director, Friends Equipment Heavy Machinery Pvt. Ltd., India',
   },
   {
      Id: '11',
      tImg: timg16,
      tsImg: tsimg16,
      name: 'Dr. Michael Conner',
      slug: "https://www.linkedin.com/in/michael-conner-ed-d-82033a21/",
      title: "CEO/Founder @ Agile Evolutionary Group, USA",
   },
   
   {
      Id: '12',
      tImg: timg18,
      tsImg: tsimg18,
      name: 'Nate Zeleznick',
      slug: 'https://www.linkedin.com/in/nate-zeleznick/',
      title: 'Founder, Integrated Ascension Method (I AM), TV Show Host, USA',
   },
   {
      Id: '12',
      tImg: timg19,
      tsImg: tsimg19,
      name: 'Ankit Bhageria',
      slug: 'https://www.linkedin.com/in/bhageriaankit/',
      title: 'Managing Partner, Optimix Advisors | Ex-Nykaa.com - Fund Raise, IPO, IR and M&A, India',
   },
   {
      Id: '14',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Dabboo Ratnani',
      slug: 'https://www.linkedin.com/in/dabboo/?originalSubdomain=in',
      title: 'Founder, Dabboo Ratnani Photography, India',
   },
  
]

export default Teams;
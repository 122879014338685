import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards: Sustainability Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Discover the Forttuna Sustainability Awards, where sustainability meets innovation. Join us to celebrate your achievements and gain global recognition!" />
            <meta property="og:title" content="Forttuna Global Excellence Awards: Sustainability Awards" />
            <meta property="og:description" content="Discover the Forttuna Sustainability Awards, where sustainability meets innovation. Join us to celebrate your achievements and gain global recognition!" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="sustainability awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='Forttuna Global Excellence Awards: Sustainability Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna sustainability awards" />
                        </div>
                        <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Sustainability Awards</h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}>First-Ever Jury-Led Sustainability Awards: Shine on the Global Stage</h1>
                        <p>Sustainability Awards at Forttuna honor companies making significant strides in sustainability. Not only companies, forward-thinking leaders shaping a greener future need to be celebrated for their positive impact.</p>
                        <p>
                            The Forttuna Global Excellence Awards are designed to celebrate your global achievements. This is your chance to showcase your innovative practices, network with industry leaders, and enhance your brand's reputation as a
                            <a href="https://professional.dce.harvard.edu/programs/building-a-sustainable-organization-challenges-opportunities-and-strategies/">sustainable</a> organization. All this on a global stage. Join us in celebrating positive environmental
                            and social impact!
                        </p>
                        <h3 style={{color: '#fff'}}>What are The Forttuna Global Excellence Sustainability Awards?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>
                                <strong>Recognizes Sustainability Leadership:</strong> Celebrates your outstanding contributions to sustainable business practices and join a prestigious network of
                                <a href="https://www.forttuna.co/business-leaders-edition">industry</a> pioneers.
                            </li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Brings Expert Validation:</strong> Ensures a rigorous, unbiased selection process conducted by a panel of esteemed industry experts.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Shapes Future Trade:</strong> Acknowledges your role in driving sustainable international commerce.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Connects Global Leaders:</strong> Forge valuable connections and collaborations with like-minded professionals.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Elevates Brand's Sustainability Reputation:</strong> Gain worldwide recognition and enhance your brand's association with sustainability.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in The</strong> Sustainability Awards?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Gain global recognition for your sustainability innovations.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Celebrate your team's expertise.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Network with global sustainability leaders and foster valuable collaborations.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Upgrade your brand's sustainability reputation and attract eco-conscious consumers.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Garner coverage from press and position your company as a sustainability leader.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Receive a fair and unprejudiced assessment by a panel of esteemed international experts.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>The Journey to Recognition: Participation Process</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Participant Interview:</strong> Potential participants are interviewed to assess their suitability.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Application Submission:</strong> Interviewees submit their formal participation application.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Questionnaire Completion:</strong> Selected participants complete a detailed questionnaire.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Jury Review:</strong> The jury reviews the questionnaires and deliberates to select the final participants.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Category for Sustainability Awards</h3>
                        <p><strong>Marketing</strong> has category for sustainability.</p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sustainability Leader of the Year</li>
                        </ol>
                        <p>Other awards in Marketing include:</p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Advertising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategy Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Commercial Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Content Marketer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Analytics Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Female Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Humanitarian Philanthropy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Business Idea of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Integrated Marketing Communications Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Media &amp; Advertising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sales Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Media Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>VP of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}><strong>Join Us in Celebrating Sustainability Excellence!</strong></h4>
                        <p>Showcase your commitment to a sustainable future and network with global sustainability leaders at the Sustainability Awards.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now!</a></p>
                        <p>Learn More About <a href="https://forttuna.co/global-entertainment-awards/">Global Entertainment Award</a>!</p>
                        <p>Know About <a href="https://forttuna.co/best-business-awards-in-india/">Best Business Awards in India</a>.</p>
                        <h5 style={{color: '#fff'}}><strong>Forttuna Global Excellence Awards</strong></h5>
                        <p>
                            The <a href="https://www.forttuna.co/">Forttuna Global Excellence Awards</a>&nbsp;honor exceptional business leaders and organizations. Mark your calendars for the upcoming award ceremonies:
                            <strong>6th and 7th, of December 2024 in Dubai</strong> and <strong>May 2025, in Mumbai.</strong> Outstanding leaders and their achievements across diverse industries are going to illuminate the award ceremony.</p>

                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;

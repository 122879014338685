import timg from '../images/resource/ritu-khare-mini.jpg'
import timg1 from '../images/resource/kaiser-raja-mini.jpg'

import tsimg from '../images/resource/ritu-khare-mini.jpg'
import tsimg1 from '../images/resource/kaiser-raja-mini.jpg'

// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Dr. Ritu Khare',
      slug: "https://www.linkedin.com/in/ritukhare",
      title: "Consultant Surgeon at King’s College Hospital London in Dubai | Medical Director Women Surgical Clinic IRAC, UAE",
   },
   {
      Id: '2',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Kaiser Raja',
      slug: "https://www.linkedin.com/in/kaiser-raja-47217416",
      title: "Consultant in Liver Diseases and Gastroenterology, King's College Hospital London, UAE",
   },
   
]

export default Teams;
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Jury.jpg';
import Navbar from '../../components/Navbar/Navbar';
import AllJuryIndia from '../../components/AllJuryIndia/AllJuryIndia';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer2 from '../../components/footer2/Footer2';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';

const SpeakerPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Jury India– Recognizing Excellence with Industry Experts';
    }, []);
    return (
        <><Helmet>
        <meta name="description" content="Discover the distinguished professionals and experts who make up the Forttuna Awards India jury, ensuring fairness and credibility in the selection process." />
        <meta property="og:title" content="Forttuna Jury India– Recognizing Excellence with Industry Experts" />
        <meta property="og:description" content="Discover the distinguished professionals and experts who make up the Forttuna Awards India jury, ensuring fairness and credibility in the selection process." />
        <meta property="og:image" content={hero1} />
        <meta property="og:url" content={hero1} />
        <meta name="keywords" content="Forttuna Jury India" />
        <meta name="twitter:card" content="summary_large_image" />
    </Helmet>   
        <Fragment>
            <Navbar />
            <section className="page-title banner-jury" >
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">The Jury</div>
                            <h2 className="page-title_heading">The Jury</h2>
                        </div>
                    </div>
                </div>
            </section>
            <AllJuryIndia/>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default SpeakerPage;

import React from 'react'
import { Link } from 'react-router-dom'
import SpecialInvitees from '../../api/SpecialInvitees'
import shape1 from '../../images/background/pattern-4.png'
import shape2 from '../../images/background/pattern-6.png'
import shape3 from '../../images/background/pattern-5.png'

const Speakers = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-one speakers-onn" id="speakers">
            {/* <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div> */}
            <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="auto-container" style={{marginTop: '120px',marginBottom: '40px'}}>
                {/* <div className="sec-title title-anim centered">
                    <div className="sec-title_title">Our Speakers</div>
                    <h2 className="sec-title_heading">Special Invitees</h2>
                </div> */}
                <div>
                    <h2 style={{textAlign: 'center', fontSize: '30px', fontWeight: 'bold', color: '#fff'}}>To Be Announced</h2>
                </div>
            </div>
        </section>
    )
}

export default Speakers;

import timg from '../images/resource/wael-al-masri.jpg'
import timg1 from '../images/resource/ritu-khare-mini.jpg'
import timg2 from '../images/resource/kaiser-raja-mini.jpg'
import timg3 from '../images/resource/suzan-al-ghanem.jpg'
import timg4 from '../images/resource/michael-schreider.jpg'
import timg5 from '../images/resource/maria-militsopoulou.jpg'

import tsimg from '../images/resource/wael-al-masri.jpg'
import tsimg1 from '../images/resource/ritu-khare-mini.jpg'
import tsimg2 from '../images/resource/kaiser-raja-mini.jpg'
import tsimg3 from '../images/resource/suzan-al-ghanem.jpg'
import tsimg4 from '../images/resource/michael-schreider.jpg'
import tsimg5 from '../images/resource/maria-militsopoulou.jpg'

// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Wael Al-Masri',
      slug: "https://www.linkedin.com/in/wael-almasri",
      title: "CEO and Founder at Slick Group [FZ LLE] & Vamp Up Marketing Services, UAE",
   },
   {
      Id: '2',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Ritu Khare',
      slug: "https://www.linkedin.com/in/ritukhare",
      title: "Consultant Surgeon at King’s College Hospital London in Dubai | Medical Director Women Surgical Clinic IRAC, UAE",
   },
   {
      Id: '3',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Dr. Kaiser Raja',
      slug: "https://www.linkedin.com/in/kaiser-raja-47217416",
      title: "Consultant in Liver Diseases and Gastroenterology, King's College Hospital London, UAE",
   },
   {
      Id: '4',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Suzan Al Ghanem',
      slug: "https://www.linkedin.com/in/suzan-al-ghanem-4158a441",
      title: "Section Head - Organizational Resilience and Business Continuity @ Environment Agency of Abu Dhabi, UAE",
   },
   {
      Id: '5',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Michael Schreider',
      slug: "https://www.linkedin.com/in/michael-schreider-47883a13",
      title: "Principal of International Schools Group (ISG), Saudi Arabia",
   },
   {
      Id: '6',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Maria Militsopoulou',
      slug: "https://www.linkedin.com/in/maria-militsopoulou-innovation-management-digitalmarketing-technology-venturecapital-startups",
      title: "Cyprus Country Chair Humanity, Technology & Innovation Wing, G100: Mission Million, Cyprus",
   },
   
]

export default Teams;
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Awards-Page-Banner.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Awards from '../../components/A1/A1';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const EventsPage = (props) => {
    useEffect(() => {
        document.title = 'Celebrating Excellence | Forttuna Awards Across Sectors';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="The Forttuna Awards honor outstanding leaders, businesses, and innovators across industries. Celebrate Excellence and global impact." />
            <meta property="og:title" content="Celebrating Excellence | Forttuna Awards Across Sectors" />
            <meta property="og:description" content="The Forttuna Awards honor outstanding leaders, businesses, and innovators across industries. Celebrate Excellence and global impact." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="keywords" content="Excellence" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title banner-award">
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Awards</div>
                            <h2 className="page-title_heading">Awards</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Awards</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <Awards />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventsPage;

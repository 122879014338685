import React from 'react'
import blogs from '../../api/blogs'
import { Link } from 'react-router-dom'
import bImg from '../../images/background/4.jpg'
import blogImg1 from "../../images/resource/Article-1.jpg";
import blogImg2 from "../../images/resource/Article-2.jpg";
import blogImg3 from "../../images/resource/Article-3.jpg";
import blogImg4 from "../../images/resource/Article-4.jpg";
import blogImg5 from "../../images/resource/Article-5.jpg";
import blogImg6 from "../../images/resource/Article-6.jpg";
import blogImg7 from "../../images/resource/Article-7.jpg";
import blogImg8 from "../../images/resource/Article-8.jpg";
import blogImg9 from "../../images/resource/Article-9.jpg";
import blogImg10 from "../../images/resource/Reena-Jain-a.jpg";
import blogImg11 from "../../images/resource/Pavan-Pidugu-a.jpg";
import blogImg12 from "../../images/resource/Dr-Sameet-Gill-a.jpg";
import blogImg13 from "../../images/resource/Dr-Rahul-Chaudhary.jpg";
import blogImg14 from "../../images/resource/Linda-Salvin-a.jpg";

const BlogSection2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="news-two"  id='blog'>
            <div className="auto-container">
                <div className="sec-title">
                    <div className="d-flex justify-content-between align-items-end flex-wrap">
                        <div className="title-box title-anim">
                            <h2 className="sec-title_heading">Media Coverage</h2>
                        </div>
                        <div className="button-box title-anim">
                            <Link to="/newsroom/" className="theme-btn btn-style-one">
                                <span className="btn-wrap">
                                    <span className="text-one">View More</span>
                                    <span className="text-two">View More</span>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="row clearfix" style={{justifyContent: 'center'}}>
                    {/* {blogs.slice(0, 3).map((blog, Bitem) => ( */}
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/the-entrepreneurial-spirit-of-dr-linda-salvin-mph-phd-in-the-world-of-spiritual-healing/95160/1'  target="_blank"><img src={blogImg14} alt="" /></a>
                                    <img src={blogImg14} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/the-entrepreneurial-spirit-of-dr-linda-salvin-mph-phd-in-the-world-of-spiritual-healing/95160/1'  target="_blank">The entrepreneurial spirit of Dr. Linda Salvin, MPH, PhD in the world of spiritual healing</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/the-entrepreneurial-spirit-of-dr-linda-salvin-mph-phd-in-the-world-of-spiritual-healing/95160/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/Leading-technological-innovation-and-resilience/95193/1'  target="_blank"><img src={blogImg11} alt="" /></a>
                                    <img src={blogImg11} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/Leading-technological-innovation-and-resilience/95193/1'  target="_blank">Leading technological innovation and resilience</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/Leading-technological-innovation-and-resilience/95193/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/Bridging-innovation-and-equity-in-dentistry/95199/1'  target="_blank"><img src={blogImg12} alt="" /></a>
                                    <img src={blogImg12} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/Bridging-innovation-and-equity-in-dentistry/95199/1'  target="_blank">Bridging innovation and equity in dentistry</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/Bridging-innovation-and-equity-in-dentistry/95199/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/how-this-scientistled-beauty-brand-has-elevated-the-standards-of-indian-skincare/95158/1'  target="_blank"><img src={blogImg13} alt="" /></a>
                                    <img src={blogImg13} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/how-this-scientistled-beauty-brand-has-elevated-the-standards-of-indian-skincare/95158/1'  target="_blank">How this scientist-led beauty brand has elevated the standards of Indian skincare</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/how-this-scientistled-beauty-brand-has-elevated-the-standards-of-indian-skincare/95158/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/Reena-Jain-A-trailblazing-leader-in-technology-and-innovation/95200/1'  target="_blank"><img src={blogImg10} alt="" /></a>
                                    <img src={blogImg10} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/Reena-Jain-A-trailblazing-leader-in-technology-and-innovation/95200/1'  target="_blank">A trailblazing leader in technology and innovation</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/Reena-Jain-A-trailblazing-leader-in-technology-and-innovation/95200/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/forttuna-global-excellence-awards-2024-celebrating-the-leaders-driving-global-innovation-and-impact/94868/1'  target="_blank"><img src={blogImg9} alt="" /></a>
                                    <img src={blogImg1} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/forttuna-global-excellence-awards-2024-celebrating-the-leaders-driving-global-innovation-and-impact/94868/1'  target="_blank">Forttuna Global Excellence Awards 2024: Celebrating the leaders driving global innovation and impact</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/forttuna-global-excellence-awards-2024-celebrating-the-leaders-driving-global-innovation-and-impact/94868/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/empowering-change-the-remarkable-leaders-behind-global-innovation/94869/1'  target="_blank"><img src={blogImg2} alt="" /></a>
                                    <img src={blogImg2} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/empowering-change-the-remarkable-leaders-behind-global-innovation/94869/1'  target="_blank">Empowering change: The remarkable leaders behind global innovation</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/empowering-change-the-remarkable-leaders-behind-global-innovation/94869/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/from-healthcare-to-business-recognising-global-leaders-changing-the-world/94867/1'  target="_blank"><img src={blogImg7} alt="" /></a>
                                    <img src={blogImg3} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/from-healthcare-to-business-recognising-global-leaders-changing-the-world/94867/1'  target="_blank">From healthcare to business: Recognising global leaders changing the world</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/from-healthcare-to-business-recognising-global-leaders-changing-the-world/94867/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/celebrating-excellence-in-health-amp-wellness-forttuna-global-excellence-award-winners/94866/1'  target="_blank"><img src={blogImg6} alt="" /></a>
                                    <img src={blogImg4} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/celebrating-excellence-in-health-amp-wellness-forttuna-global-excellence-award-winners/94866/1'  target="_blank">Celebrating excellence in health & wellness: Forttuna Global Excellence Award winners</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/celebrating-excellence-in-health-amp-wellness-forttuna-global-excellence-award-winners/94866/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="news-block_two col-lg-4 col-md-6 col-sm-12">
                            <div className="news-block_two-inner">
                                {/* <div className="news-block_two-date">{blog.create_at}</div> */}
                                <div className="news-block_two-image">
                                <a href='https://www.forbesindia.com/article/brand-connect/celebrating-global-leaders-in-healthcare-innovation-at-the-forttuna-global-excellence-awards-2024/94865/1'  target="_blank"><img src={blogImg8} alt="" /></a>
                                    <img src={blogImg5} alt="" />
                                </div>
                                <div className="news-block_two-content" style={{height: '260px'}}>
                                    <h4 className="news-block_one-title"><a href='https://www.forbesindia.com/article/brand-connect/celebrating-global-leaders-in-healthcare-innovation-at-the-forttuna-global-excellence-awards-2024/94865/1'  target="_blank">Celebrating global leaders in healthcare innovation at the Forttuna Global Excellence Awards 2024</a></h4>
                                    <a href='https://www.forbesindia.com/article/brand-connect/celebrating-global-leaders-in-healthcare-innovation-at-the-forttuna-global-excellence-awards-2024/94865/1'  target="_blank"className="news-block_one-more">Read More</a>
                                </div>
                            </div>
                        </div>
                        
                    {/* ))} */}

                </div>
            </div>
        </section>
    )
}

export default BlogSection2;
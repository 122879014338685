import React from 'react';

const YouTubePlayer = () => {
  return (
    <div className='videoo-div-s'>
      <iframe
        className='videoo-s'
        width="100%"
        height="500"
        src="https://www.youtube.com/embed/9IJGLXvGM_4?mute=0&controls=1&loop=1&playlist=9IJGLXvGM_4&rel=0&modestbranding=1"

        title="YouTube video player"
        frameBorder="2"
        autoplay
        allow="encrypted-media"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubePlayer;

import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import VideoPlayer from '../Thankyou/video';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Payment-successful-page.webp';

const TermPage = (props) => {

    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Thank you';
    }, []);
    return (
        <>
        <Helmet>
                <meta name="description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Thank you" />
                <meta property="og:description" content="The Forttuna Global Excellence Awards is dedicated to recognizing and honoring outstanding achievements across various industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1  } />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
        
        <Fragment>
            <Navbar />
            <section className="page-title" >
                <video autoPlay loop muted playsInline style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, zIndex: 0, width: '100%', height: '100%', objectFit: 'cover', }} >
                    <source src="https://forttuna.co/video/home-video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box" style={{marginTop: '70px'}}>

                            <div className="page-title_big">&nbsp;</div>
                            <h2 className="page-title_heading">&nbsp;</h2>
                        </div>
                    </div>
                </div>
            </section> 
            <section className="privacy-section">
                <div className="auto-container">
                    <div className="privacy-content desk-div">
                    <div className="row">
                        <div className="col-md-6">
                        <h2>Your Payment Was Successfully Processed</h2>
                        <br/>
                        <div className="col-md-6 mob-viso"><VideoPlayer/></div>
                        <p>
                        Thank you for completing your payment for the Participation fee with Forttuna Global Excellence Awards. We are thrilled to have you join us on this journey of celebrating excellence and innovation.
                        </p>
                        <p><strong><u>PLEASE NOTE:</u></strong> Participation in the Forttuna Global Excellence Awards does not guarantee a win. Our program is jury-led and merit-based. Our winners are decided by the Jury. The Participation Application Fee is non-refundable.</p>
                        <h2>Next Steps:</h2>
                        <p>– Our Nomination Team will reach out to you within the next 48 hours, guiding you to complete your Application.</p>
                        <p>– You will receive an email notification with instructions on how to proceed with your Application.</p>
                        <p>– Please check your junk/spam folder periodically for our emails, as they may occasionally end up there. This will help ensure you receive all our email notifications.</p>
                        </div>
                        <div className="col-md-6 desk-viso"><VideoPlayer/></div>
                        <div className="col-md-12"><h2>Stay Connected:</h2>
                        <p>– In the meantime, feel free to explore our website to learn more about our award categories, past winners, and upcoming events.</p>
                        <p>– Connect with us on social media to stay updated on the latest news and announcements from the Forttuna Global Excellence Awards.</p>
                        <h2>Contact Us:</h2>
                        <p>– If you have any questions or need further assistance, please don’t hesitate to reach out to our support team at <a href="mailto:contact@forttuna.co">contact@forttuna.co</a>. We are here to help you every step of the way.</p>
                        <p>Thank you once again for choosing the Forttuna Global Excellence Awards. We look forward to celebrating your achievements!</p></div>
                    </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default TermPage;

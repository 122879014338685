import timg from '../images/resource/Anjlee.jpg'
import timg0 from '../images/resource/amit-sheth.webp'
import timg12 from '../images/resource/shailza-taneja.jpg'
import timg14 from '../images/resource/Ravi-Nayar.jpg'
import timg15 from '../images/resource/Mantosh-Roy.jpg'
import timg18 from '../images/resource/Sanchita_Sen-mini.jpg'
import timg19 from '../images/resource/Rajiv-Jalota.jpg'

import tsimg from '../images/resource/Anjlee.jpg'
import tsimg0 from '../images/resource/amit-sheth.webp'
import tsimg12 from '../images/resource/shailza-taneja.jpg'
import tsimg14 from '../images/resource/Ravi-Nayar.jpg'
import tsimg15 from '../images/resource/Mantosh-Roy.jpg'
import tsimg18 from '../images/resource/Sanchita_Sen-mini.jpg'
import tsimg19 from '../images/resource/Rajiv-Jalota.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Amit Sheth',
      slug: 'javascript:void(0)',
      title: "Award-winning Serial Entrepreneur & Philanthropist, India",
   },
   {
      Id: '2',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Dr. Shailza Taneja',
      slug: 'https://www.linkedin.com/in/shailzataneja/',
      title: 'Founder & Director, CreativExpression, India',
   },
   {
      Id: '3',
      tImg: timg,
      tsImg: tsimg,
      name: 'Dr. Anjlee Prakash',
      slug: 'https://www.linkedin.com/in/anjleeprakash/',
      title: 'Founder & Chairperson, Learning Links Foundation, India',
   },
   {
      Id: '5',
      tImg: timg14,
      tsImg: tsimg14,
      name: 'Ravi Nayar',
      slug: 'https://www.linkedin.com/in/ravi-nayar-2829648/?originalSubdomain=in',
      title: 'Entrepreneur, Hospitality Veteran, Managing Director at Mardi Gras Entertainment & Off Beat Adventure, India',
   },
   {
      Id: '6',
      tImg: timg15,
      tsImg: tsimg15,
      name: 'Mantosh Roy',
      slug: 'https://www.linkedin.com/in/mantyroy/',
      title: 'Serial Entrepreneur, Founder, Start-up Advisor, Angel Investor, Ex Nykaa, Co-Founder, Sammmm Beauty, India',
   },
   // {
   //    Id: '7',
   //    tImg: timg16,
   //    tsImg: tsimg16,
   //    name: 'Varinder Kumar Verma',
   //    slug: 'https://www.linkedin.com/in/varindervermaom21000/',
   //    title: 'Head - HR Strategy & Transformation (Corporate Human Resources), Maruti Suzuki India Limited, India',
   // }, 
   {
      Id: '9',
      tImg: timg18,
      tsImg: tsimg18,
      name: 'Sanchita Sen',
      slug: 'https://www.linkedin.com/in/sanchita-sen-4560297a',
      title: 'Strategic HR Visionary | Psychology Driven-Innovation Inspired | Shaping Cultures, Empowering Talent, and Driving Multi-Industry Transformation, India',
   },
   {
      Id: '10',
      tImg: timg19,
      tsImg: tsimg19,
      name: 'Rajiv Jalota',
      slug: "https://www.linkedin.com/in/rajiv-jalota-retd-ias-a2533a16/",
      title: "Chairperson of Mumbai Port Authority and Indian Ports Association, India",
   },

]

export default Teams;
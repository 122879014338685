
import timg5 from '../images/resource/gaurav-singh.jpg'
import timg6 from '../images/resource/Amey-1.jpg'
import timg7 from '../images/resource/kishor.jpg'
import timg8 from '../images/resource/dhananajy-bokolia-1.jpg'
import timg9 from '../images/resource/Ayush-Kumar-1.jpg'
import timg13 from '../images/resource/mandar.jpg'


import tsimg5 from '../images/resource/gaurav-singh.jpg'
import tsimg6 from '../images/resource/Amey-1.jpg'
import tsimg7 from '../images/resource/kishor.jpg'
import tsimg8 from '../images/resource/dhananajy-bokolia-1.jpg'
import tsimg9 from '../images/resource/Ayush-Kumar-1.jpg'
import tsimg13 from '../images/resource/mandar.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Gaurav Singh',
      slug: 'Gaurav Singh',
      title: 'Vice President - Operations',
      link : 'https://www.linkedin.com/in/gsingh04/',
   },
   {
      Id: '2',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Amey Kadam',
      slug: 'Amey Kadam',
      title: 'AVP - Marketing & Communications and Operations',
      link : 'https://www.linkedin.com/in/amey-kadam-325a87202/',
   },
   {
      Id: '3',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Kishor Kumbhar',
      slug: 'Kishor Kumbhar',
      title: 'Senior Manager - Delegate Relations, Marketing & Communications',
      link : 'https://www.linkedin.com/in/kishor-kumbhar-154386245/',
   },
   {
      Id: '5',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Ayush Kumar',
      slug: 'Ayush Kumar',
      title: 'Senior Full Stack Web Developer',
      link : 'https://www.linkedin.com/in/ayush-kumar-10131dev/',
   },
   {
      Id: '6',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Mandar Sarnobat',
      slug: 'Mandar Sarnobat',
      title: 'Digital Media Strategist',
      link : 'https://www.linkedin.com/in/mandar-sarnobat-114545201/',
   },
   // {
   //    Id: '7',
   //    tImg: timg3,
   //    tsImg: tsimg3,
   //    name: 'Saurabh Sharma',
   //    slug: 'Saurabh Sharma',
   //    title: 'Manager – Client Relations',
   //    link : 'https://www.linkedin.com/in/saurabh-sharma-b5b45751/',
   // },
   // {
   //    Id: '11',
   //    tImg: timg10,
   //    tsImg: tsimg10,
   //    name: 'Meltron D’mello',
   //    slug: 'Meltron D’mello',
   //    title: 'Consultant - Growth',
   // },
   // {
   //    Id: '12',
   //    tImg: timg11,
   //    tsImg: tsimg11,
   //    name: 'Russel Dsilva',
   //    slug: 'Russel Dsilva',
   //    title: 'Consultant - Growth',
   // },
   
   // {
   //    Id: '8',
   //    tImg: timg8,
   //    tsImg: tsimg8,
   //    name: 'Harnold Min',
   //    slug: 'Harnold-Min',
   //    title: 'Starbucks CEO',
   // },

]

export default Teams;
import timg1 from '../images/resource/Linda-Salvin-big.jpg'
import timg1a from '../images/resource/Rajan-Bandelkar-big.jpg'
import timg1b from '../images/resource/Randhir-Sud-big.jpg'
import timg2 from '../images/resource/Gauranga-Das-big.jpg'
import timg3 from '../images/resource/Eng.-Ahmed-Al-Hosani-big.jpg'
import timg3a from '../images/resource/New PrAnil-Chanchlanioject-big.jpg'
import timg4 from '../images/resource/Subramanyam-Yadavalli-big.jpg'
import timg4a from '../images/resource/Manjula-Anagani-big.jpg'
import timg5 from '../images/resource/Moran-Cerf-big.jpg'
import timg6 from '../images/resource/Raajeev-Kumaar-big.jpg'
import timg7 from '../images/resource/Nate-zeleznick-big.jpg'
import timg7a from '../images/resource/Michael-Conner-big.jpg'
import timg9 from '../images/resource/Ankit-Bhageria-big.jpg'
import timg10 from '../images/resource/Dabboo-Ratnani-big.jpg'


import tsimg1 from '../images/resource/Linda-Salvin-big.jpg'
import tsimg1a from '../images/resource/Rajan-Bandelkar-big.jpg'
import tsimg1b from '../images/resource/Randhir-Sud-big.jpg'
import tsimg2 from '../images/resource/Gauranga-Das-big.jpg'
import tsimg3 from '../images/resource/Eng.-Ahmed-Al-Hosani-big.jpg'
import tsimg3a from '../images/resource/New PrAnil-Chanchlanioject-big.jpg'
import tsimg4 from '../images/resource/Subramanyam-Yadavalli-big.jpg'
import tsimg4a from '../images/resource/Manjula-Anagani-big.jpg'
import tsimg5 from '../images/resource/Moran-Cerf-big.jpg'
import tsimg6 from '../images/resource/Raajeev-Kumaar-big.jpg'
import tsimg7 from '../images/resource/Nate-zeleznick-big.jpg'
import tsimg7a from '../images/resource/Michael-Conner-big.jpg'
import tsimg9 from '../images/resource/Ankit-Bhageria-big.jpg'
import tsimg10 from '../images/resource/Dabboo-Ratnani-big.jpg'

const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Linda Salvin, MPH, PhD',
      slug: 'https://linkedin.com/in/linda-salvin-mph-phd-85009411/',
      title: 'Veteran Radio Talk Show Host, Healer and Metaphysician, Creator of Wicks of Wisdom, USA',
   },
   {
      Id: '1',
      tImg: timg1a,
      tsImg: tsimg1a,
      name: 'Rajan Bandelkar',
      slug: 'https://www.linkedin.com/in/rajan-bandelkar-47710b128/',
      title: 'President, NAREDCO | Founder & MD, Raunak Group, India',
   },
   {
      Id: '1',
      tImg: timg1b,
      tsImg: tsimg1b,
      name: 'Padmashri Dr. Randhir Sud',
      slug: 'https://www.linkedin.com/in/dr-randhir-sud-884659a3/',
      title: "Chairman, Institute of Digestive and Hepatobiliary Sciences, Medanta the Medicity, NCR, India",
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Gauranga Das',
      slug: 'https://in.linkedin.com/in/gaurangadasofficial',
      title: 'Spiritual Leader, Sustainability Advocate, and Social Innovator, India',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Eng. Ahmed Al Hosani',
      slug: 'https://linkedin.com/in/singer-ahmed-alhosani-8a329a231',
      title: "UAE's First Classical Singer, Emirati Musicians' Association",
   },
   {
      Id: '3',
      tImg: timg3a,
      tsImg: tsimg3a,
      name: 'Anil Chanchlani',
      slug: 'javascript:void(0)',
      title: 'Promoter, AC Group of Companies, India',
   },
   {
      Id: '4',
      tImg: timg4a,
      tsImg: tsimg4a,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'https://www.linkedin.com/in/dr-manjula-anagani-a5283718/   ',
      title: 'Clinical Director, Women and Child Institute, Care Hospital, India',
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Subramanyam Yadavalli',
      slug: 'https://linkedin.com/in/subramanyam-yadavalli-6ba30059',
      title: 'CEO, Apollo Hospitals, AP & International, India',
   },
   {
      Id: '5',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Moran Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '6',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Raajeev Kumaar',
      slug: 'linkedin.com/in/raajeev-kumaar-96a588102',
      title: 'Managing Director, Friends Equipment Heavy Machinery Pvt. Ltd., India',
   },
   {
      Id: '7',
      tImg: timg7a,
      tsImg: tsimg7a,
      name: 'Dr. Michael Conner',
      slug: "https://www.linkedin.com/in/michael-conner-ed-d-82033a21/",
      title: "CEO/Founder @ Agile Evolutionary Group, USA",
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Nate Zeleznick',
      slug: 'https://www.linkedin.com/in/nate-zeleznick/',
      title: 'Founder, Integrated Ascension Method (I AM), TV Show Host, USA',
   },
   {
      Id: '7',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Ankit Bhageria',
      slug: 'https://www.linkedin.com/in/bhageriaankit/',
      title: 'Managing Partner, Optimix Advisors | Ex-Nykaa.com - Fund Raise, IPO, IR and M&A, India',
   },
   {
      Id: '7',
      tImg: timg10,
      tsImg: tsimg10,
      name: 'Dabboo Ratnani',
      slug: 'https://www.linkedin.com/in/dabboo/?originalSubdomain=in',
      title: 'Founder, Dabboo Ratnani Photography, India',
   },

]

export default Teams;

import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/Amarjit-Singh.jpg'
import timg5 from '../images/resource/Arun-Kumar-Singh.jpg'
import timg6 from '../images/resource/amit-sheth.webp'
import timg7 from '../images/resource/Subramanyam-Yadavalli.webp'
import timg11 from '../images/resource/Anjlee.jpg'
import timg12 from '../images/resource/shailza-taneja.jpg'
import timg13 from '../images/resource/Manoj-Mohta.jpg'
import timg14 from '../images/resource/Ravi-Nayar.jpg'
import timg15 from '../images/resource/Mantosh-Roy.jpg'
import timg16 from '../images/resource/Varinder-Kumar-Verma.jpg'
import timg17 from '../images/resource/Ankit-Bhagheria.jpg'
import timg18 from '../images/resource/Dr.-Geetika-Gupta.jpg'
import timg19 from '../images/resource/Dr.-Nabhit-Kapur.jpg'
import timg20 from '../images/resource/Dr.-Burzin-Khan.jpg'
import timg21 from '../images/resource/Rajan-Balakrishna-Raje.jpg'
import timg22 from '../images/resource/Rashi-Khanna.jpg'
import timg23 from '../images/resource/Deepak-Rao.jpg'
import timg24 from '../images/resource/Vijay-uttarwar-s.jpg'
import timg25 from '../images/resource/Sanchita_Sen-mini.jpg'
import timg26 from '../images/resource/Rajiv-Jalota.jpg'
import timg27 from '../images/resource/Praveen-Garg.jpg'



import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/Amarjit-Singh.jpg'
import tsimg5 from '../images/resource/Arun-Kumar-Singh.jpg'
import tsimg6 from '../images/resource/amit-sheth.webp'
import tsimg11 from '../images/resource/Anjlee.jpg'
import tsimg12 from '../images/resource/shailza-taneja.jpg'
import tsimg14 from '../images/resource/Ravi-Nayar.jpg'
import tsimg15 from '../images/resource/Mantosh-Roy.jpg'
import tsimg18 from '../images/resource/Dr.-Geetika-Gupta.jpg'
import tsimg19 from '../images/resource/Dr.-Nabhit-Kapur.jpg'
import tsimg20 from '../images/resource/Dr.-Burzin-Khan.jpg'
import tsimg21 from '../images/resource/Rajan-Balakrishna-Raje.jpg'
import tsimg22 from '../images/resource/Rashi-Khanna.jpg'
import tsimg24 from '../images/resource/Vijay-uttarwar-s.jpg'
import tsimg25 from '../images/resource/Sanchita_Sen-mini.jpg'
import tsimg26 from '../images/resource/Rajiv-Jalota.jpg'



const Teams = [
   {
      Id: '1',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'https://www.linkedin.com/in/bimal-chhajer-24a4aa29/',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '3',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Amit Sheth',
      slug: "javascript:void(0)",
      title: 'Award-winning Serial Entrepreneur & Philanthropist, India',
   },
   // {
   //    Id: '7',
   //    tImg: timg7,
   //    tsImg: tsimg7,
   //    name: 'Subramanyam Yadavalli',
   //    slug: 'https://www.linkedin.com/in/subramanyam-yadavalli-6ba30059/',
   //    title: 'CEO, Apollo Hospitals, India',
   // },
   {
      Id: '8',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr.(Brig) Amarjit Singh',
      slug: 'https://www.linkedin.com/in/dr-brig-amarjit-singh-50b1458/',
      title: 'CEO & Principal Director, Dr. D. Y. Patil Medical College Hospital & Research Centre, India',
   },
   {
      Id: '9',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Arun Kumar Singh',
      slug: 'https://www.linkedin.com/in/dr-arun-kumar-singh-54147547/',
      title: 'Development Commissioner & Additional Chief Secretary of Health, Government of Jharkhand, India',
   },
   {
      Id: '10',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Dr. Anjlee Prakash',
      slug: 'https://www.linkedin.com/in/anjleeprakash/',
      title: 'Founder & Chairperson, Learning Links Foundation, India',
   },
   {
      Id: '11',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Dr. Shailza Taneja',
      slug: 'https://www.linkedin.com/in/shailzataneja/',
      title: 'Founder & Director, CreativExpression, India',
   },

   {
      Id: '13',
      tImg: timg14,
      tsImg: tsimg14,
      name: 'Ravi Nayar',
      slug: 'https://www.linkedin.com/in/ravi-nayar-2829648/?originalSubdomain=in',
      title: 'Entrepreneur, Hospitality Veteran, Managing Director at Mardi Gras Entertainment & Off Beat Adventure, India',
   },
   {
      Id: '14',
      tImg: timg15,
      tsImg: tsimg15,
      name: 'Mantosh Roy',
      slug: 'https://www.linkedin.com/in/mantyroy/',
      title: 'Serial Entrepreneur, Founder, Start-up Advisor, Angel Investor, Ex Nykaa, Co-Founder, Sammmm Beauty, India',
   },
   // {
   //    Id: '15',
   //    tImg: timg16,
   //    tsImg: tsimg16,
   //    name: 'Varinder Kumar Verma',
   //    slug: 'https://www.linkedin.com/in/varindervermaom21000/',
   //    title: 'Head - HR Strategy & Transformation (Corporate Human Resources), Maruti Suzuki India Limited, India',
   // },
   {
      Id: '17',
      tImg: timg18,
      tsImg: tsimg18,
      name: 'Dr. Geetika Gupta',
      slug: 'https://www.linkedin.com/in/drgeetikamittalgupta',
      title: 'Founder & Medical Director - ISAAC Luxe, India',
   },
   {
      Id: '18',
      tImg: timg19,
      tsImg: tsimg19,
      name: 'Dr. Nabhit Kapur',
      slug: 'https://www.linkedin.com/in/drnabhitkapur',
      title: 'President World Leaders for Mental Health, India',
   },
   {
      Id: '19',
      tImg: timg20,
      tsImg: tsimg20,
      name: 'Dr. Burzin Khan',
      slug: 'https://www.linkedin.com/in/opusdentalspecialities/',
      title: 'Proprietor, OPUS Dental Specialities, India',
   },
   {
      Id: '20',
      tImg: timg21,
      tsImg: tsimg21,
      name: 'Rajan Balakrishna Raje',
      slug: 'https://www.linkedin.com/in/rajan-raje-602847184/',
      title: 'Head of SME Committee (Western Region), Indian Chamber of Commerce | Board Member, Bombay Chamber of Commerce and Industry, India',
   },
   {
      Id: '21',
      tImg: timg22,
      tsImg: tsimg22,
      name: 'Rashi Khanna',
      slug: 'https://www.linkedin.com/in/rashi-khanna-8b990b44/',
      title: 'Founder & CEO, KYAA MATLAB | Advisory, Central Board of Film Certification, India',
   },
   // {
   //    Id: '22',
   //    tImg: timg23,
   //    tsImg: tsimg23,
   //    name: 'Deepak Rao',
   //    slug: 'https://www.linkedin.com/in/deepak-rao-b4136612/',
   //    title: 'Product Owner / Consultant, Consultancy Services, India',
   // },
   {
      Id: '23',
      tImg: timg24,
      tsImg: tsimg24,
      name: 'Vijay Uttarwar',
      slug: 'https://www.linkedin.com/in/vijay-uttarwar-a571a614/',
      title: 'CEO, Naturell India Pvt. Ltd., India',
   },
   {
      Id: '24',
      tImg: timg25,
      tsImg: tsimg25,
      name: 'Sanchita Sen',
      slug: 'https://www.linkedin.com/in/sanchita-sen-4560297a',
      title: 'Strategic HR Visionary | Psychology Driven-Innovation Inspired | Shaping Cultures, Empowering Talent, and Driving Multi-Industry Transformation, India',
   },
   {
      Id: '25',
      tImg: timg26,
      tsImg: tsimg26,
      name: 'Rajiv Jalota',
      slug: "https://www.linkedin.com/in/rajiv-jalota-retd-ias-a2533a16/",
      title: "Chairperson of Mumbai Port Authority and Indian Ports Association, India",
   },

   
]

export default Teams;
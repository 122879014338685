import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Green-Leaders-Page-Banner.jpg';
import Navbar from '../../components/Navbar/Navbar';
import pIcon2 from '../../images/background/Why-Attend-Forttuna-Awards.jpg';
import PageTitle from '../../components/pagetitle/PageTitle'
import Jury from '../../components/Jury2025/Team';
import Features from '../../components/Features';
import Business from '../../components/GreenLeader/GreenLeader';
import Features2 from '../../components/FeaturesB';
import VideoArea from '../../components/VideoAreab/VideoArea';
import PartnerSection from '../../components/PartnerSection';
import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import shape2 from '../../images/background/pattern-6.png'
import pIcon5 from '../../images/background/Key-Highlights-of-the-Gala-Green-Banner.jpg';
import Accordion from '@mui/material/Accordion';
import pIcon3 from '../../images/background/Home-Page---Awardee-Deliverables-Green-Leader-Banner.jpg';
import pIcon4 from '../../images/background/FFTB-Banner.png';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const AboutPage = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        document.title = 'Shaping a Greener Future | Forttuna Green Leaders Edition';
    }, []); // The empty array ensures this runs only once when the component moun
    return (
        <>
        <Helmet>
            <meta name="description" content="The Forttuna Green Leaders Awards honor visionaries driving sustainability and environmental impact. Discover the leaders shaping a greener future." />
            <meta property="og:title" content="Shaping a Greener Future | Forttuna Green Leaders Edition" />
            <meta property="og:description" content="The Forttuna Green Leaders Awards honor visionaries driving sustainability and environmental impact. Discover the leaders shaping a greener future." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="keywords" content="Forttuna Green Leaders" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title banner-green" >
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">&nbsp;</div>
                            <h2 className="page-title_heading">&nbsp;</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Health</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <Business />
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div>
                    <img src={pIcon3} alt="Description of the image" />
                </div>
            </section>
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div>
                    <img src={pIcon2} alt="Description of the image" />
                </div>
            </section>
            
            {/* <Features2 /> */}
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div className="sec-title title-anim centered">
                        {/* <div className="sec-title_title">Our Speakers</div> */}
                        <h2 className="sec-title_heading">Who Can Participate?</h2>
                    </div>
                <div>
                    <img src={pIcon4} alt="Description of the image" />
                </div>
            </section>
            {/* <Features /> */}
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div className="sec-title title-anim centered">
                        {/* <div className="sec-title_title">Our Speakers</div> */}
                        {/* <h2 className="sec-title_heading">Who Can Participate?</h2> */}
                    </div>
                <div>
                    <img src={pIcon5} alt="Description of the image" />
                </div>
            </section>
            <section className="speakers-one speakers-onn" id="speakers">
                {/* <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div> */}
                <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
                <div className="auto-container" style={{marginTop: '120px',marginBottom: '120px'}}>
                    <div className="sec-title title-anim centered">
                        {/* <div className="sec-title_title">Our Speakers</div> */}
                        <h2 className="sec-title_heading">The Jury</h2>
                    </div>
                    <div>
                        <h2 style={{textAlign: 'center', fontSize: '30px', fontWeight: 'bold', color: '#fff'}}>To Be Announced</h2>
                    </div>
                </div>
            </section>
            <VideoArea />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default AboutPage;

import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Participation-Guideline.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PastWinner from '../../components/PastWinner/PastWinner';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer2 from '../../components/footer2/Footer2';
import Accordion from '@mui/material/Accordion';
import pIcon4 from '../../images/background/Participation-Guidelines-Banner.png';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';

const SpeakerPage = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        document.title = 'How to Participate in the Forttuna Awards | Official Guidelines';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Want to be part of the Forttuna Awards? Read the official participation guidelines, eligibility criteria, and application process to get started." />
            <meta property="og:title" content="How to Participate in the Forttuna Awards | Official Guidelines" />
            <meta property="og:description" content="Want to be part of the Forttuna Awards? Read the official participation guidelines, eligibility criteria, and application process to get started." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="keywords" content="How to Participate" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title banner-guide">
                <div className="auto-container" >
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">&nbsp;</div>
                            <h2 className="page-title_heading">&nbsp;</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>PARTICIPATION GUIDELINES</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <section style={{marginTop: '120px'}}> 
                <div className="sec-title title-anim centered">
                    <h2 class="sec-title_heading">Invitation Criteria For The Excellence Awards</h2>
                </div>
                <div className='mrl-12'>
                    <p className='pera'>Want to be part of the Forttuna Awards? Read the official participation guidelines, eligibility criteria, and application process to get started. We meticulously curate a list of distinguished group of <a href="https://forttuna.co/award-categories/">individuals and organizations</a> from a vast pool of candidates for this award of excellence. We consider various factors in the excellence awards including recommendations, educational background, professional experience, leadership qualities, achievements, and, where applicable, the financial stability of companies or organizations.
                    <br/>
                    <br/>
                    This curated list is then invited to <a href="https://participate.forttuna.co/">participate</a> in the Forttuna Global Excellence Awards Program. Participants are required to first submit an application and then are requested to complete a questionnaire based on the jury’s judging criteria for the evaluation in the award of excellence.
                    <br/>
                    <br/>
                    Subsequently, our esteemed and distinguished panel of jury meticulously evaluates the information and selects the final awardees through a rigorous process in the global awards. This meticulous approach ensures that only the most deserving individuals and entities are included in the final list of awardees of the global awards.</p>
                </div>
            </section>
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div className="sec-title title-anim centered">
                        {/* <div className="sec-title_title">Our Speakers</div> */}
                        <h2 className="sec-title_heading">Participation Process</h2>
                    </div>
                <div>
                    <img src={pIcon4} alt="Description of the image" />
                </div>
            </section>
           
            <div className="auto-container" style={{marginTop: '-30px'}}>
                <div className="sec-title title-anim centered">
                    {/* <div className="sec-title_title">Our Speakers</div> */}
                    <h2 className="sec-title_heading">Things To Remember</h2>
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{marginTop: '40px'}}>
                        <ul className="accordion-box style-two">
                        <li className="accordion block active-block">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Does participating in the Forttuna Global Excellence Awards Program guarantee that I will win? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p style={{color:'#fff'}}>Participation in the Fortuna Global Excellence Awards Program does not guarantee a win. Our program is jury-led and merit-based. Our winners are decided by the Jury.</p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Is this a paid award? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p style={{color:'#fff'}}> No, the Forttuna Global Excellence Awards is not a paid award. We celebrate and honor outstanding individuals and organizations in their respective industry based on their merits, contributions, and achievements rather than financial considerations. It is our commitment to recognizing excellence.</p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>How did I get invited for an interview? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p style={{color:'#fff'}}>The shortlisting process is a result of meticulous research conducted by our dedicated team in collaboration with a research unit. We employ comprehensive criteria, including recommendations, educational qualifications, professional experience, outstanding accomplishments, and recognition in your respective sector. This thorough evaluation identifies individuals who stand out within the industry.</p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>How does the Jury evaluate applications? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p style={{color:'#fff'}}>The Jury evaluates applications through a comprehensive three-level internal screening process. This method ensures a thorough and unbiased assessment of each candidate&rsquo;s qualifications. Here&rsquo;s how the evaluation is structured:</p>
                                        <p style={{color:'#fff'}}><strong>Initial Review by the Preliminary Jury:</strong> This first level involves a preliminary assessment of applications. The jury conducts an initial review to determine adherence to the application guidelines.</p>
                                        <p style={{color:'#fff'}}><strong>Detailed Review by a Jury Lead:</strong> Applications that pass the initial review proceed to this stage, where a Jury Lead conducts an in-depth evaluation of the candidate&rsquo;s application, submission, and supporting documents along with the candidate&rsquo;s credentials, achievements, and overall suitability.</p>
                                        <p style={{color:'#fff'}}><strong>Final Review by the Grand Jury:</strong> The final level of scrutiny is conducted by our Grand Jury. Only the applications that excel through the previous stages make it to this round, ensuring that only the most outstanding candidates are considered.</p>
                                        <p style={{color:'#fff'}}><strong>PLEASE NOTE:</strong><strong> Scores are assigned objectively based on the evidence and information provided by each candidate in their questionnaire responses. APPLICATIONS THAT DO NOT MEET THE REQUIRED STANDARDS AT ANY LEVEL ARE ELIMINATED FROM THE PROCESS.</strong></p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className="accordion block active-block">
                                    <AccordionSummary
                                        expandIcon={""}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className='acc-btn'
                                    >
                                        <Typography>Will I receive feedback from the jury on my application? <div className="icon fa fa-angle-down"></div></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                        <p style={{color:'#fff'}}>Detailed feedback from the jury is not provided; however, you will be notified of the outcome once the deliberation is complete.</p>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <PastWinner/> */}
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default SpeakerPage;
